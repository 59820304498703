.button {
    cursor: pointer;
    align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 0.8em;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 0.95em;
    justify-content: center;
    border-radius: 0.25em;
}

.noStyle {
    cursor: pointer;
    color: #ccc;
}

.noStyle.active {
    font-weight: bold;
    color: #fff;
}

.buttonLabel {
    padding-left: 0.35em;
}

.buttonIcon {
    font-size: 1.3em;
}

.largeButtonIcon {
    font-size: 1.9em;
}

.button-icon.gray-with-white-shadow {
    color: #606060;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.whiteTheme {
    background-color: #fff;
    color: #0657a8;
}

.greenTheme {
    background-color: green;
    color: white;
}

.blueTheme {
    background-color: #1f76dc;
    color: #fff;
}

.flexColumn {
    flex-direction: column;
}
.flexColumn .buttonLabel {
    padding-left: 0;
    padding-top: 0.3em;
}

.button.disabled {
    cursor: default;
    opacity: 0.5;
}

.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    row-gap: 1em;
    background-color: #1f1f28;
    height: 100vh;
    z-index: -5;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    margin: 2em auto;
    max-width: 300px;
    overflow: visible;
    width: 100%;
    z-index: 10;
    margin-bottom: 220px;
    animation: fade-in 1s;
}

.loginContainer > input {
    display: inline-block;
    padding: 7px 10px;
    margin-bottom: 1.5em;
    border-radius: 4px;
    border: 1px solid transparent;
    height: 43px;
    outline: none;
}

.loginContainer .loginBtn {
    height: 45px;
}

.title {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.8em 0em 1.5em 0;
    color: #fff;
    text-transform: initial;
}

.title i {
    font-size: 0.8em;
    margin-right: 1em;
}

.errorMsg {
    font-family: "proxima_novalight";
    color: #ff554c;
    margin-top: 2em;
}

.invalid {
    border: 1px solid #ff554c !important;
} 

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
