.reviewItem {
  margin: 1em 0;
}

.reviewItem {
  padding: 1em;
}

.itemHeader {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  flex-wrap: wrap;
  row-gap: 1em;
}

.designStyle {
  color: #999;
  font-size: 1em;
  text-transform: uppercase;
}

.grayGood {
  flex: 2;
  font-size: 1.2em;
  margin: 0 1em 0 0;
}

.itemNumber {
  color: #999;
  font-family: "proxima_novaregular";
  font-size: 0.9em;
  font-style: italic;
  text-transform: uppercase;
}

.deleteButton {
  background-color: transparent;
  border: 0 none;
  padding: 1em 0;
  text-transform: uppercase;
}

.price,
.totalItems {
  color: #ccc;
  flex: 1;
  font-size: 1.2em;
  margin: 0 1em 0 0;
  white-space: nowrap;
}

.subTotalLabel {
  color: #999;
  font-size: 1.2em;
  white-space: nowrap;
}

.subTotalValue {
  color: #02be02;
}

.body {
  display: flex;
  flex-direction: row;
  padding: 15px 0px 0px 0px;
}

.columnOne {
  flex: 2 1;
}

.columnTwo,
.rosterTotalContainer {
  flex: 3 1;
  height: fit-content;
}

.columnTwo tbody > tr:first-child {
  color: #1f76dc;
}

.bodyColumnOne {
  composes: columnOne;
  width: 40%;
}

.editDesignButton {
  composes: ui-button-clear from "../index.module.scss";
  margin: 0 auto;
}

.bodyEditDesignButton {
  composes: editDesignButton;
}

.footerEditDesignButton {
  composes: editDesignButton;
  font-size: 16px;
  text-transform: uppercase;
}

.editRosterButton {
  composes: ui-button-clear from "../index.module.scss";
  margin: 0 auto;
  font-size: 16px;
  text-transform: uppercase;
}

.deleteProductButton {
  composes: ui-button-clear from "../index.module.scss";
  margin: 0 auto;
}

.thumbsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rContainer {
  width: 300px;
  overflow: hidden;
}

.removeItem {
  display: flex;
  justify-content: flex-end;
}

.spinnerContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  composes: flex-row from "../index.module.scss";
}

.colSide {
  display: flex;
  flex: 0 0 150px;
  justify-content: center;
}

.colSide > button {
  font-size: 20px;
}

.mobileFooterButtons {
  display: flex;
  justify-content: center;
}

.mobileFooterButtons button {
  margin: 0 !important;
  text-transform: none;
  font-size: 16px;
}

.mobileFooterButtons .deleteButton {
  padding: 1em !important;
}

.rosterTotalContainer {
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  padding-bottom: 1em;
}

.thumbLoading {
  color: #f0f0f0;
  font-size: 50px;
}

.rosterGridTable {
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 300px;
  overflow: auto;
  width: 60%;
}

.reviewItem {
  margin-left: 3em;
  margin-right: 3em;
}

.reviewItem:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.imgLogo {
  width: 40px;
  height: 40px;
  margin: 3em;
}

/* Tablets */

@media (min-width: 750px) and (max-width: 1024px) {
  .colSide {
    display: none;
  }
  .body,
  .rosterTotalContainer {
    flex-direction: column;
  }
  .bodyColumnOne {
    border: 0 none;
    display: flex;
    justify-content: center;
    flex: none;
  }
  .bodyColumnOne,
  .rosterGridTable {
    width: 100%;
  }
  .bodyEditDesignButton {
    display: none;
  }
  .colSide {
    display: none;
  }
  .deleteButton {
    display: block;
    margin: 0 !important;
    font-size: 16px;
  }
  .footer {
    display: flex;
    justify-content: center;
    flex-direction: unset;
    margin-top: 1em;
  }
  .display-roster-grid {
    margin-top: 20px;
  }
  .thumbsContainer {
    margin-bottom: 1em;
  }
}

/* Larger than 750 */

@media only screen and (min-width: 751px) {
  .bodyColumnOne,
  .columnOne,
  .columnTwo .editRosterButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bodyEditDesignButton {
    display: none;
  }
}

/* Larger than 1024 */
@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .body,
  .rosterTotalContainer {
    display: flex;
    flex-direction: column;
  }
  .footer {
    justify-content: space-evenly;
  }
  .rosterTotalContainer {
    padding-bottom: 1em;
  }
  .columnOne,
  .columnTwo {
    flex: 1 1;
  }
  .bodyColumnOne,
  .rosterGridTable {
    width: 100%;
  }
  .thumbsContainer {
    margin-bottom: 1em;
  }
}

/* Smaller than 750 */

@media only screen and (max-width: 750px) {
  .reviewItem {
    margin: 1em 0;
  }
  .body,
  .rosterTotalContainer {
    flex-direction: column;
  }
  .bodyColumnOne {
    border: 0 none;
    display: flex;
    justify-content: center;
    flex: none;
    width: 100%;
  }
  .bodyEditDesignButton {
    display: none;
  }
  .colSide {
    display: none;
  }
  .deleteButton {
    display: block;
    margin: 0 !important;
    font-size: 16px;
    padding: 1em;
  }
  .footer {
    display: flex;
    justify-content: center;
    flex-direction: unset;
  }
  .columnOne,
  .columnTwo,
  .rosterTotalContainer {
    flex: none;
  }
  .columnTwo {
    margin: 0;
  }
  .display-roster-grid {
    margin-top: 20px;
  }
  .rContainer {
    margin: 10px;
  }
  .rosterGridTable {
    width: 100%;
    display: grid;
    justify-content: stretch;
  }
  .thumbsContainer {
    margin-bottom: 1em;
  }
}

/* Smaller than 650 */

@media only screen and (max-width: 650px) {
  .grayFood,
  .price,
  .subTotal {
    font-size: 1em;
  }
  .designStyle {
    font-size: 0.9em;
  }
  .itemNumber {
    font-size: 0.8em;
  }
}

/* Smaller than 500 */

@media only screen and (max-width: 500px) {
  .reviewItem {
    margin: 1em 0;
    padding: 1em;
  }
  .grayFood,
  .price,
  .subTotal {
    font-size: 0.8em;
  }
  .designStyle,
  .itemNumber {
    font-size: 0.8em;
  }
  .footerEditDesignButton {
    display: block;
  }
  .bodyColumnOne {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .rContainer {
    width: unset;
  }
  .rosterTotalContainer {
    padding-bottom: 1em;
  }
  .columnOne {
    padding-right: 5px;
  }
  .columnTwo {
    padding-left: 5px;
  }
  .rContainer {
    margin: 0;
  }
  .rosterGridTable {
    width: 100%;
    display: grid;
    justify-content: stretch;
  }
}
