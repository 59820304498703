*,
*::before,
*::after {
  box-sizing: border-box;
}

.mainContainer {
  width: 100%;
}

.ordersTable {
  border-collapse: collapse;
  margin: 1em;
  box-shadow: 0 2px 10px rgb(72 73 73 / 20%);
  border-radius: 6px;
  background: #ffffff;
}

.ordersTable td {
  text-align: left;
  height: 4em;
  padding: 0 1em;
  color: #5e5d5e;
}

.ordersTable th {
  padding: 1em;
  text-align: left;
  text-transform: uppercase;
}

.ordersTable th, td {
  border-bottom: 1px solid lightgrey;
}

.ordersTable tr:hover {
  background-color: #f5f5f5;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3em 0 0.5em;
  padding: 0 3em;
}

.headerText {
  font-size: 1.75em;
  font-weight: bold;
  text-transform: uppercase;
  color: #5e5d5e;
}

.unopened {
  margin-left: 1em;
  color: red;
  font-size: 0.8em;
}

.ordersTable td:nth-child(1) {
  white-space: nowrap;
}

@media screen and (max-width: 850px) {
  table.ordersTable {
    background: transparent;
  }

  table.ordersTable thead {
    display: none;
  }

  table.ordersTable tr {
    padding: 5px;
    display: block;
    margin: 0 0 1em;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  table.ordersTable tr:last-child {
    margin: 0 0 0;
  }

  table.ordersTable td {
    border-bottom: 1px dotted #ccc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  table.ordersTable td:last-child {
    border-bottom: 0;
  }

  table.ordersTable td.tdBtn {
    justify-content: center;
    font-family: "proxima_novabold";
  }

  table.ordersTable td:last-child div {
    width: 15em;
  }

  table.ordersTable td:first-child {
    font-family: "proxima_novabold";
  }

  table.ordersTable td:before {
    content: attr(data-label);
    font-weight: bold;
    font-family: "proxima_novabold";
  }

  table.ordersTable td {
    font-family: "proxima_novalight";
  }
}
