.grayGoodsContainer .header {
    margin: 3em 0 3em;
    padding: 0 3em;
    display: flex;
    flex-direction: row;
    column-gap: 2em;
    justify-content: flex-start;
    align-items: baseline;
}

.grayGoodsContainer .headerText {
    font-size: 1.75em;
    font-weight: bold;
    text-transform: uppercase;
    color: #5e5d5e;
}

.grayGoodsContainer .sport {
    margin-left: 1em;
    margin-top: 4px;
    color: #1f76dc;
    font-size: 1.25em;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: baseline;
}

.grayGoodsContainer .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    overflow: auto;
    width: 100%;
}

.grayGoodsContainer .list .item {
    // border: 1px solid #ddd;
    //border: 0.2rem solid #ecececd9;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 2rem;
    max-width: 19rem !important;
    padding: 0em 2em;
    box-shadow: 0 2px 10px rgb(72 73 73 / 20%);
    border-radius: 6px;
    justify-content: center;
    background: #FFFFFF;
}

.grayGoodsContainer .list .name {
    color: #575757;
    font-size: 1.125rem;
    text-transform: uppercase;
    padding: 0.5em 0 !important;
    //border-bottom: 1px solid #ddd;
    text-align: center;
}

.grayGoodsContainer input[type="number"]::-webkit-inner-spin-button,
.grayGoodsContainer input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.grayGoodsContainer .wrapInput {
    padding-top: .5em;
    padding-bottom: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 1em;
}

.grayGoodsContainer input {
    border: 1px solid #ccc;
    color: #575757;
    flex: 1 1;
    outline: none;
    padding: 0.5em;
    border-radius: 4px;
    line-height: 135%;
}

.grayGoodsContainer span {
    padding: 0 !important;
}

.grayGoodsContainer .wrapInput span {
    margin-right: 5px;
    color: #575757;
    font-size: 1.25em;
}

.grayGoodsContainer .grayGoodImg {
    height: 120px;
}

.grayGoodsContainer .btnContainer {
    padding: 1em 0 0;
}

.grayGoodsContainer .btnContainer div {
    width: 120px;
    height: 38px;
}

.grayGoodsContainer .divider {
    border-top: 1px solid #ddd !important;
    margin: 1em 0 0;
    width: 100%;
}

.updatePriceBtn {
    transition: all .5s ease;
}

.updatePriceBtn > span {
    transition: all .3s ease-in-out;
}

@media only screen and (max-width: 500px) {
    .display-roster-grid th,
    .display-roster-grid tr {
        font-size: 0.9em;
    }
}
