.App {
  display: flex;
  height: 100vh;
}

.MainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 3em;
  overflow-y: auto;
  max-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

a:hover {
  filter:brightness(85%);
}

@media only screen and (max-width: 1150px) {
  .MainContent {
    padding: 0 2em;
  }
}

//Smaller than 850px
@media only screen and (max-width: 850px) {
  .App{
    flex-direction: column;
  }

  .MainNav {
    height: 5em;
    flex-direction: row;
  }

  .MainContent {
    margin-left: 0;
    margin-right: 0;
    padding: 0 1em;
  }

  .wrapper {
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
  }

  .overlay {
    background-color: #000;
    cursor: pointer; /* Required so onClick event fires */
    height: 100vh;
    left: 0;
    opacity: 0.7;
    overflow: hidden;
    pointer-events: all;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 99;
  }
}